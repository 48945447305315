import BigPicture from 'bigpicture'
import EventUtil from '../Util/EventUtil'

export default class Lightbox {
  constructor () {
    this.classes = {
      editorImage: '.o-js-editor-lightbox',
    }
  }

  init () {
    EventUtil.delegateListeners('click', this.classes.editorImage, (e, el) => {
      BigPicture({ el: el })
    })
  }
}
