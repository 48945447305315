import Header from './Header'
import Pagination from './Pagination'
import Search from './Search'

export default class Layout {
  constructor () {
    this.header = new Header()
    this.pagination = new Pagination()
    this.search = new Search()
  }

  init () {
    this.header.init()
    this.pagination.init()
    this.search.init()
  }
}
