import Swiper from 'swiper'
import { Autoplay, EffectFade, Pagination } from 'swiper/modules'
import GlobalUtil from '../Util/GlobalUtil'

export default class HomeBanner {
  constructor () {
    this.classes = {
      element: '.o-js-slider-home-banner'
    }

    this.sliders = []

    Swiper.use([Autoplay, EffectFade, Pagination])
  }

  init () {
    window.addEventListener('load', e => this.initSliders())
  }

  initSliders() {
    GlobalUtil.forEach(document.querySelectorAll(this.classes.element), (index, slider) => {
      const sliderId = slider.id

      this.sliders[sliderId] = new Swiper(slider, {
        effect: 'fade',
        loop: true,
        speed: 500,
        autoplay: {
          delay: 4000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      })
    })
  }
}
