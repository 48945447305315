import EventUtil from './Util/EventUtil'
import GlobalUtil from './Util/GlobalUtil'

export default class Account {
  constructor () {
    this.menu = document.querySelector('.o-js-account-menu')
    this.responsiveTableClass = '.o-js-account-table-mobile-friendly'

    if (this.menu) {
      this.menuItems = this.menu.querySelectorAll('.o-js-account-menu-item')
    }
  }

  init () {
    if (this.menu) this.setMenu()

    this.setResponsiveTables()
    this.catchEstimateUpdate()

    // EVENT RESIZE
    EventUtil.addListeners(window, 'resize', e => this.setResponsiveTables())
  }

  setMenu () {
    GlobalUtil.forEach(this.menuItems, (index, item) => {
      // INIT
      if (item.classList.contains('active') || item.querySelector('.active')) {
        item.classList.add('active')

        GlobalUtil.forEach(item.querySelectorAll('.o-js-account-menu-item-list'), (index, el) => {
          el.style.display = 'block'
        })
      }

      // CLICK
      const trigger = item.querySelector('.o-js-account-menu-item-trigger')

      if (trigger) {
        EventUtil.addListeners(trigger, 'click', e => {
          if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
            const triggerExpanded = trigger.getAttribute('aria-expanded')

            $(this.menuItems).not(item).find('.o-js-account-menu-item-list').slideUp(300)
            $(item).find('.o-js-account-menu-item-list').slideToggle(300)
            if (triggerExpanded) trigger.setAttribute('aria-expanded', triggerExpanded === 'false')
          }
        })
      }
    })
  }

  setResponsiveTables () {
    const tables = document.querySelectorAll(this.responsiveTableClass)

    GlobalUtil.forEach(tables, (index, table) => {
      const isTableEmpty = table.querySelectorAll('.o-table-empty-cell').length
      const IsTableMobileFormatted = table.querySelectorAll('.o-table-mobile-main').length

      if (!isTableEmpty) {
        if (window.innerWidth < 768 && !IsTableMobileFormatted) {
          this.setTableMobileFormat(table)
        } else if (window.innerWidth >= 768 && IsTableMobileFormatted) {
          this.unsetTableMobileFormat(table)
        }
      }
    })
  }

  setTableMobileFormat (table) {
    const headers = table.querySelectorAll('thead th')
    const items = table.querySelectorAll('tbody tr')

    // Add header label in each row items
    GlobalUtil.forEach(headers, (index, header) => {
      const label = header.innerHTML
      const linkedCells = table.querySelectorAll(`tbody td:nth-child(${index + 1}):not(.o-table-mobile-skip-head):not(:empty)`)

      if (!GlobalUtil.isBlank(label)) {
        GlobalUtil.forEach(linkedCells, (index, cell) => {
          if (!cell.querySelector('.o-table-mobile-label')) {
            const mobileLabel = document.createElement('p')

            mobileLabel.classList.add('o-table-mobile-label')
            mobileLabel.append(label)
            cell.prepend(mobileLabel)
          }
        })
      }
    })

    // Split datas & actions in different blocks
    GlobalUtil.forEach(items, (index, item) => {
      const datas = item.querySelectorAll('td:not(.o-table-mobile-action)')
      const actions = item.querySelectorAll('td.o-table-mobile-action')
      const mainBlock = document.createElement('div')

      mainBlock.classList.add('o-table-mobile-main')
      mainBlock.append(...datas)
      item.append(mainBlock)

      // Put actions in side block
      if (actions.length) {
        const sideBlock = document.createElement('div')

        sideBlock.classList.add('o-table-mobile-side')
        sideBlock.append(...actions)
        item.append(sideBlock)
      }
    })
  }

  unsetTableMobileFormat (table) {
    const items = table.querySelectorAll('tbody tr')

    // Reconstruct table
    GlobalUtil.forEach(items, (index, item) => {
      const mainBlock = item.querySelector('.o-table-mobile-main')
      const sideBlock = item.querySelector('.o-table-mobile-side')

      // Main
      if (mainBlock) {
        item.append(...mainBlock.childNodes)
        mainBlock.remove()
      }

      // Side
      if (sideBlock) {
        item.append(...sideBlock.childNodes)
        sideBlock.remove()
      }
    })
  }

  catchEstimateUpdate () {
    EventUtil.delegateListeners('ajax:success', '.o-js-account-estimate-update', (e, data) => {
      const response = e.detail[0]

      if (!GlobalUtil.isBlank(response.html)) {
        response.html.forEach(item => {
          document.querySelector(item.selector).innerHTML = item.content
        })
      }

      this.setResponsiveTables()
    })
  }
}
