import EventUtil from '../Util/EventUtil'
import FormUtil from '../Util/FormUtil'
import GlobalUtil from '../Util/GlobalUtil'

import Confirmation from './Confirmation'

export default class Email {
  constructor () {
    this.inputs = document.querySelectorAll('input.email')
    this.inputsConfirmation = Confirmation.getInputs(this.inputs)
    this.inputsToCheck = FormUtil.getInputsToCheck(this.inputs)
  }

  init () {
    // LISTEN INPUTS TO CHECK VALIDITY
    EventUtil.addListeners(this.inputsToCheck, 'keyup', (e, input) => this.checkInput(input))

    // INPUTS CONFIRMATION
    GlobalUtil.forEach(this.inputsConfirmation, (index, input) => {
      Confirmation.listenInputMatch(input, () => this.checkInput(input))

      EventUtil.addListeners(input, 'paste', e => {
        e.preventDefault()
        return false
      })
    })
  }

  checkInput (input) {
    const status = this.validateInput(input)

    FormUtil.displayInputStatus(input, status)
  }

  validateInput (input) {
    if (!input.value) return
    if (input.classList.contains('o-js-input-confirmation') && Confirmation.validateMatch(input) !== 'valid') return Confirmation.validateMatch(input)
    if (!this.validatePattern(input.value)) return 'invalid'
    return 'valid'
  }

  validatePattern (pattern) {
    const regex = /\S+@\S+\.\S+/

    return regex.test(pattern)
  }
}
