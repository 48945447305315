import Rails from '../Rails/ujs'

import EventUtil from '../Util/EventUtil'
import FormUtil from '../Util/FormUtil'
import GlobalUtil from '../Util/GlobalUtil'

import Flash from '../Tool/Flash'

export default class Cart {
  constructor () {
    this.classes = {
      addToBtn: '.o-js-add-to-cart',
      addToModal: '.o-js-add-to-cart-modal',
      addToModalCloseBtn: '.o-js-add-to-cart-modal-close',
      confirmBtn: '.o-js-cart-confirm',
      estimateBtn: '.o-js-cart-estimate',
      form: '.o-js-cart-form',
      orderItem: '.o-js-order-item',
      orderItemQuantity: '.o-js-order-item-quantity',
      removeOrderBtn: '.o-js-remove-order-item'
    }
    this.orderItems = document.querySelectorAll(this.classes.orderItem)
    this.refreshTargetQuery = null
  }

  init () {
    EventUtil.delegateListeners('click', this.classes.addToBtn, (e, el) => {
      e.preventDefault()
      this.addItem(el)
    })

    EventUtil.delegateListeners('click', this.classes.addToModalCloseBtn, (e, el) => {
      e.preventDefault()
      const modal = document.querySelector(this.classes.addToModal)
      modal.textContent = ''
    })

    if (document.querySelector(this.classes.form)) {
      // On order item deletion
      EventUtil.delegateListeners('click', this.classes.removeOrderBtn, (e, el) => {
        e.preventDefault()
        this.removeItem(el.closest(this.classes.orderItem))
      })

      // On quantity change
      EventUtil.delegateListeners('change', this.classes.orderItemQuantity, (e, el) => {
        const item = el.closest(this.classes.orderItem)

        if (el.value <= 0) el.value = 1
        this.refreshTargetQuery = `${this.classes.orderItem}[data-order-item-id="${item.dataset.orderItemId}"] ${this.classes.orderItemQuantity}`
        this.submitForm()
      })

      // On form submit
      EventUtil.delegateListeners('submit', this.classes.form, (e, el) => {
        e.preventDefault()

        // Send form datas
        Rails.ajax({
          url: el.getAttribute('action'),
          type: 'POST',
          dataType: 'json',
          data: FormUtil.serialize(el),
          success: data => {
            // Display flash message
            if (!GlobalUtil.isBlank(data.flash) && !GlobalUtil.isBlank(data.flash.message)) {
              const flash = new Flash()
              flash.trigger(data.flash.type, data.flash.message)
            }

            // Add html refreshed content
            if (!GlobalUtil.isBlank(data.html)) {
              data.html.forEach(item => {
                document.querySelector(item.selector).innerHTML = item.content
              })
            }

            // Set focus after content refresh
            if (this.refreshTargetQuery) {
              const target = document.querySelector(this.refreshTargetQuery)

              if (target) target.focus()
              this.refreshTargetQuery = null
            }
          }
        })

        // Disable cart items
        const confirmBtn = document.querySelector(this.classes.confirmBtn)
        const estimateBtn = document.querySelector(this.classes.estimateBtn)

        if (confirmBtn) confirmBtn.classList.add('is-disabled')
        if (estimateBtn) estimateBtn.classList.add('is-disabled')

        GlobalUtil.forEach(document.querySelectorAll(this.classes.removeOrderBtn), (index, item) => {
          item.classList.add('is-disabled')
        })

        GlobalUtil.forEach(document.querySelectorAll(this.classes.orderItemQuantity), (index, input) => {
          input.disabled = true
        })
      })
    }
  }

  addItem (trigger) {
    if (trigger.dataset.url) {
      Rails.ajax({
        url: trigger.dataset.url,
        type: 'POST',
        dataType: 'json',
        success: data => {
          if (!GlobalUtil.isBlank(data.flash) && !GlobalUtil.isBlank(data.flash.message)) {
            const flash = new Flash()
            flash.trigger(data.flash.type, data.flash.message)
          }

          if (!GlobalUtil.isBlank(data.html)) {
            data.html.forEach(item => {
              document.querySelector(item.selector).innerHTML = item.content
            })
          }
        }
      })
    }
  }

  removeItem (item) {
    item.querySelector('.o-js-order-item-destruction').value = 1
    this.submitForm()
  }

  submitForm () {
    Rails.fire(document.querySelector(this.classes.form), 'submit')
  }
}
