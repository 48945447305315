import EventUtil from '../Util/EventUtil'

import Anchor from './Anchor'
import Clipboard from './Clipboard'
import Locale from './Locale'
import Print from './Print'
import Toggle from './Toggle'

export default class Action {
  constructor () {
    this.anchor = new Anchor()
    this.clipboard = new Clipboard()
    this.locale = new Locale()
    this.print = new Print()
    this.toggle = new Toggle()

    this.classes = {
      disabledLinks: '.o-js-disable-link'
    }
  }

  init () {
    this.anchor.init()
    this.clipboard.init()
    this.locale.init()
    this.print.init()
    this.toggle.init()

    this.preventDisabledLinksRedirection()
  }

  preventDisabledLinksRedirection () {
    EventUtil.delegateListeners('click', this.classes.disabledLinks, e => {
      e.preventDefault()
      return false
    })
  }
}
