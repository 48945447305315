import Rails from '@rails/ujs'

/* ----- Rails UJS override for obfuscated links ----- */
Rails.href = function(el) {
  if (el.dataset.obf) { return atob(el.dataset.obf) }
  if (el.dataset.href) { return el.dataset.href }
  return el.href
}

/* ----- Custom selectors ----- */
Rails.obfuscatedLinkDisableSelector = '[data-obf][data-disable-with]:not(a), [data-obf][data-disable]:not(a)'
Rails.obfuscatedLinkClickSelector = '[data-obf][data-confirm]:not(a), [data-obf][data-method]:not(a), [data-obf][data-remote]:not([disabled]):not(a), [data-obf][data-disable-with]:not(a), [data-obf][data-disable]:not(a)'

/* ----- Custom functions ----- */
// Duplication of "Rails.enableLinkElement" function for obfuscated links
Rails.enableObfuscatedLinkElement = function(e) {
  var element = e instanceof Event ? e.target : e

  if (!Rails.matches(element, Rails.obfuscatedLinkDisableSelector)) {
    element = element.closest(Rails.obfuscatedLinkDisableSelector)
  }

  if (element === null) return

  var originalText = Rails.getData(element, 'ujs:enable-with')

  if (originalText != null) {
    element.innerHTML = originalText
    Rails.setData(element, 'ujs:enable-with', null)
  }
  element.removeEventListener('click', Rails.stopEverything)
  return Rails.setData(element, 'ujs:disabled', null)
}

// Duplication of "Rails.disableLinkElement" function for obfuscated links
Rails.disableObfuscatedLinkElement = function(e) {
  var element = e instanceof Event ? e.target : e

  if (!Rails.matches(element, Rails.obfuscatedLinkDisableSelector)) {
    element = element.closest(Rails.obfuscatedLinkDisableSelector)
  }

  if (element === null) return

  if (Rails.getData(element, 'ujs:disabled')) {
    return
  }

  var replacement = element.getAttribute('data-disable-with')

  if (replacement != null) {
    Rails.setData(element, 'ujs:enable-with', element.innerHTML)
    element.innerHTML = replacement
  }
  element.addEventListener('click', Rails.stopEverything)
  return Rails.setData(element, 'ujs:disabled', true)
}

export default Rails
