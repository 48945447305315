import EventUtil from '../Util/EventUtil'
import FormUtil from '../Util/FormUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class Checkout {
  constructor () {
    this.otherAddressRadioClass = '.o-js-checkout-other-address-radio'
    this.sameAddressCheckboxClass = '.o-js-checkout-same-address-checkbox'
    this.termsCheckboxClass = '.o-js-checkout-terms-checkbox'
  }

  init () {
    // Step : Address
    GlobalUtil.forEach(document.querySelectorAll('.o-js-checkout-address-container'), (index, container) => {
      const radios = container.querySelectorAll('input[type="radio"]')
      const otherAddressRadio = container.querySelector(this.otherAddressRadioClass)
      const sameAddressCheckbox = container.querySelector(this.sameAddressCheckboxClass)

      this.displayOtherAddress(otherAddressRadio)

      EventUtil.addListeners(radios, 'change', (e, radio) => {
        this.displayOtherAddress(otherAddressRadio)

        if (sameAddressCheckbox && radio.checked) {
          sameAddressCheckbox.checked = false
          this.displayExtraAddresses(sameAddressCheckbox, radios)
        }
      })

      if (sameAddressCheckbox) {
        this.displayExtraAddresses(sameAddressCheckbox, radios)

        EventUtil.addListeners(sameAddressCheckbox, 'change', (e, checkbox) => {
          this.displayExtraAddresses(sameAddressCheckbox, radios)
          this.displayOtherAddress(otherAddressRadio)
        })
      }
    })

    // Step : Payment
    GlobalUtil.forEach(document.querySelectorAll(this.termsCheckboxClass), (index, checkbox) => this.enablePaymentSubmit(checkbox))
    EventUtil.delegateListeners('change', this.termsCheckboxClass, (e, checkbox) => this.enablePaymentSubmit(checkbox))
  }

  displayOtherAddress (radio) {
    const container = radio.closest('.o-js-checkout-other-address-container')

    container.classList.toggle('is-opened', radio.checked)
  }

  displayExtraAddresses (checkbox, radios) {
    GlobalUtil.forEach(radios, (index, radio) => {
      if (checkbox.checked && radio.checked) radio.checked = false
      FormUtil.checkInputDisabledState(radio, checkbox.checked)
    })
  }

  enablePaymentSubmit (checkbox) {
    const container = checkbox.closest('.o-js-checkout-payment-container')
    const submitButton = container.querySelector('[type="submit"]')

    submitButton.disabled = !checkbox.checked
  }
}
