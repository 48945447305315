import ActionUtil from '../Util/ActionUtil'
import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class Anchor {
  constructor () {
    this.triggersFocus = document.querySelectorAll('.o-js-focus-on')
    this.triggersScroll = document.querySelectorAll('.o-js-scroll-to')
  }

  init () {
    // URL scroll trigger
    if (window.location.hash) {
      const target = document.querySelector(window.location.hash.trim())

      window.addEventListener('load', e => this.scrollTo(target, 50))
    }

    // Link focus triggers
    EventUtil.addListeners(this.triggersFocus, 'click keyup', (e, trigger) => {
      if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
        e.preventDefault()

        const targetId = !GlobalUtil.isBlank(trigger.dataset.target) ? trigger.dataset.target : trigger.getAttribute('href')
        const target = document.querySelector(targetId)

        if (target) {
          const firstFocusableItem = target.querySelector('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')

          this.scrollTo(target)
          if (firstFocusableItem) firstFocusableItem.focus()
        }
      }
    })

    // Link scroll triggers
    EventUtil.addListeners(this.triggersScroll, 'click keyup', (e, trigger) => {
      if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
        e.preventDefault()

        const targetId = !GlobalUtil.isBlank(trigger.dataset.target) ? trigger.dataset.target : trigger.getAttribute('href')
        const target = document.querySelector(targetId)

        if (target) this.scrollTo(target)
      }
    })
  }

  scrollTo (target, duration = 600) {
    if (target) ActionUtil.scrollTo(GlobalUtil.viewportOffset(target).top, duration)
  }
}
