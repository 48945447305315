import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class Header {
  constructor () {
    this.header = document.querySelector('.o-js-header')
    this.stickyDelta = 0
    this.lastScrollY = 0

    if (this.header) {
      this.toggleAccountButton = this.header.querySelector('.o-js-header-account-toggle')
      this.toggleAccountTargetClass = '.o-js-account-menu-container'
      this.toggleFilterButton = this.header.querySelector('.o-js-header-filter-toggle')
      this.toggleFilterTargetClass = '.o-js-search-box-filter'
      this.toggleMenuButton = this.header.querySelector('.o-js-header-menu-toggle')
      this.overlay = this.header.querySelector('.o-js-header-overlay')
      this.simpleSelects = this.header.querySelectorAll('.o-js-header-simple-select')
      this.nav = this.header.querySelector('.o-js-header-nav')
      this.searchForm = this.header.querySelector('.o-js-header-search-form')
      this.searchFormToggle = this.header.querySelector('.o-js-header-search-form-toggle')
      this.searchFormOpen = this.header.querySelector('.o-js-header-search-form-open')
      this.searchFormClose = this.header.querySelector('.o-js-header-search-form-close')

      if (this.nav) {
        this.navItems = this.nav.querySelectorAll('.o-item')
      }
    }
  }

  init () {
    if (this.header) {
      this.setStickyClass(window)
      EventUtil.addListeners(window, 'scroll', e => this.setStickyClass(window), { anim: true })

      // BUTTONS
      this.setMobileButtons()

      // OVERLAY
      EventUtil.addListeners(this.overlay, 'click', e => this.closeSide())

      // SIMPLE SELECTS
      GlobalUtil.forEach(this.simpleSelects, (index, select) => this.bindTriggerEvents(select, this.simpleSelects, 'button', 'ul'))

      // NAV ITEMS
      GlobalUtil.forEach(this.navItems, (index, item) => {
        this.bindTriggerEvents(item, this.navItems, '.o-title', '.o-submenu')
        if (item.querySelector('.is-active')) item.classList.add('is-active')
      })

      // SEARCH FORM
      EventUtil.addListeners(this.searchFormToggle, 'click', e => this.toggleSearchForm())
      EventUtil.addListeners(this.searchFormOpen, 'click', e => this.toggleSearchForm(true))
      EventUtil.addListeners(this.searchFormClose, 'click', e => this.toggleSearchForm(false))

      // EVENT RESIZE
      EventUtil.addListeners(window, 'resize', e => {
        if (window.innerWidth >= 1024) {
          this.closeSide()
        }
      })
    }
  }

  setMobileButtons () {
    // TOGGLE ACCOUNT MENU BUTTON
    if (this.toggleAccountButton) {
      this.setCustomSide(this.toggleAccountButton, this.toggleAccountTargetClass)
    }

    // TOGGLE FILTERS BUTTON
    if (this.toggleFilterButton) {
      this.setCustomSide(this.toggleFilterButton, this.toggleFilterTargetClass)
    }

    // TOGGLE MENU BUTTON
    if (this.toggleMenuButton) {
      EventUtil.addListeners(this.toggleMenuButton, 'click', e => {
        if (this.header.classList.contains('is-opened')) {
          this.closeSide()
        } else {
          this.openSide()
        }
      })
    }
  }

  setCustomSide (button, targetClass) {
    // Toggle side block
    EventUtil.addListeners(button, 'click', e => {
      const toggleTarget = document.querySelector(targetClass)

      if (toggleTarget) {
        if (toggleTarget.classList.contains('is-opened')) {
          button.classList.remove('is-active')
          this.closeSide(toggleTarget)
        } else {
          button.classList.add('is-active')
          this.openSide(toggleTarget)
        }
      }
    })

    // Close side block from outside
    EventUtil.delegateOutsideListeners('click', targetClass, e => {
      const isClickingButton = button === e.target || button.contains(e.target)
      const toggleTarget = document.querySelector(targetClass)

      if (!isClickingButton) {
        button.classList.remove('is-active')
        this.closeSide(toggleTarget)
      }
    })

    // Close side block from button inside
    EventUtil.delegateListeners('click', targetClass + '-close', (e, el) => {
      const toggleTarget = document.querySelector(targetClass)

      button.classList.remove('is-active')
      this.closeSide(toggleTarget)
    })
  }

  openSide (target = this.header) {
    target.classList.add('is-opened')
    document.querySelector('body').classList.add('has-scroll-disabled')

    if (target === this.header) {
      this.overlay.classList.add('is-visible')
    } else if (target.classList.contains(this.toggleAccountTargetClass.substring(1))) {
      this.header.classList.add('is-opened')
    }
  }

  closeSide (target = this.header) {
    target.classList.remove('is-opened')
    document.querySelector('body').classList.remove('has-scroll-disabled')

    if (target === this.header) {
      this.overlay.classList.remove('is-visible')
    } else if (target.classList.contains(this.toggleAccountTargetClass.substring(1))) {
      this.header.classList.remove('is-opened')
    }
  }

  toggleSearchForm (force) {
    this.searchForm.classList.toggle('is-visible', force)

    if (this.searchForm.classList.contains('is-visible')) {
      this.searchForm.querySelector('input').focus()
    } else {
      if (window.innerWidth >= 1024) {
        this.searchFormOpen.focus()
      }
    }
  }

  bindTriggerEvents (el, items, triggerQuery, submenuQuery) {
    const trigger = el.querySelector(triggerQuery)
    const submenu = el.querySelector(submenuQuery)
    const submenuItems = submenu ? submenu.querySelectorAll('li') : undefined

    // Click
    EventUtil.addListeners(trigger, 'click', e => {
      if (window.innerWidth < 1024) {
        const triggerExpanded = trigger.getAttribute('aria-expanded')

        if (submenu) {
          $(submenu).slideToggle(submenuItems ? submenuItems.length * 80 : 80)
        }

        if (triggerExpanded) trigger.setAttribute('aria-expanded', triggerExpanded === 'false')
      }
    })

    // Enter key press
    EventUtil.addListeners(trigger, 'keyup', e => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        const triggerExpanded = trigger.getAttribute('aria-expanded')

        // Hide other items
        GlobalUtil.forEach(items, (index, item) => {
          if (item !== el) {
            const trigger = item.querySelector(triggerQuery)
            const triggerExpanded = trigger.getAttribute('aria-expanded')
            const submenu = item.querySelector(submenuQuery)

            if (submenu) {
              $(submenu).slideUp(submenuItems ? submenuItems.length * 80 : 80)
            }

            if (triggerExpanded) trigger.setAttribute('aria-expanded', false)
          }
        })

        if (submenu) {
          $(submenu).slideToggle(submenuItems ? submenuItems.length * 80 : 80)
        }

        if (triggerExpanded) trigger.setAttribute('aria-expanded', triggerExpanded === 'false')

        if (window.innerWidth >= 1024) {
          if (submenu) {
            submenu.style.removeProperty('left')
            this.preventOutsideSubmenu(submenu)
          }
        }
      }
    })

    // Hover
    EventUtil.addListeners(trigger, 'mouseover', e => {
      if (window.innerWidth >= 1024) {
        if (submenu) {
          submenu.style.removeProperty('left')
          this.preventOutsideSubmenu(submenu)
        }
      }
    })
  }

  preventOutsideSubmenu (submenu) {
    const rect = submenu.getBoundingClientRect()
    const diff = rect.left + rect.width - window.scrollX - window.innerWidth

    if (diff > 0) submenu.style.left = submenu.offsetLeft - diff + 'px'
  }

  setStickyClass (el) {
    let scrollY = el.scrollY

    if (el !== window) scrollY = el.scrollTop
    if (Math.abs(this.lastScrollY - scrollY) <= this.stickyDelta) return

    this.header.classList.toggle('is-sticky', el.scrollY > this.stickyDelta)
    this.lastScrollY = scrollY
  }
}
