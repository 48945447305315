class Device {
  getMediaQuery () {
    if (window.innerWidth >= 1280) {
      return 'large-desktop'
    } else if (window.innerWidth < 1280 && window.innerWidth >= 1024) {
      return 'small-desktop'
    } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
      return 'tablet'
    } else if (window.innerWidth < 768 && window.innerWidth >= 480) {
      return 'mobile'
    } else {
      return 'small-mobile'
    }
  }

  getMSBrowserVersion () {
    let ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ')
    const trident = ua.indexOf('Trident/')
    const edge = ua.indexOf('Edge/')

    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
    }

    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:')
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
    }

    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
    }

    // other browser
    return false
  }
}

const instance = new Device()
Object.freeze(instance)

export default instance
