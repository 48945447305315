import EventUtil from '../Util/EventUtil'

import Flash from '../Tool/Flash'

export default class Clipboard {
  constructor () {
    this.classes = {
      trigger: '.o-js-copy-to-clipboard'
    }
  }

  init () {
    EventUtil.delegateListeners('click', this.classes.trigger, (e, trigger) => {
      e.preventDefault()

      let input
      let destroyInput = false

      // If type is URL
      if (trigger.dataset.type === 'url') {
        input = document.createElement('input')
        document.body.appendChild(input)
        input.value = window.location.href
        destroyInput = true
      }

      // Copy in clipboard
      input.select()
      document.execCommand('copy')

      // Remove input from DOM
      if (destroyInput) {
        document.body.removeChild(input)
      }

      if (trigger.dataset.message) {
        const flash = new Flash()

        flash.trigger('success', trigger.dataset.message)
      }
    })
  }
}
