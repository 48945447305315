import Rails from '../Rails/ujs'

import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class Obfuscation {
  init() {
    GlobalUtil.forEach(document.querySelectorAll(Rails.obfuscatedLinkDisableSelector), (index, link) => {
      if (Rails.getData(link, 'ujs:disabled')) {
        Rails.enableObfuscatedLinkElement(link)
      }
    })

    this.setEvents()
  }

  setEvents() {
    Rails.delegate(document, Rails.obfuscatedLinkDisableSelector, 'ajax:complete', Rails.enableObfuscatedLinkElement)
    Rails.delegate(document, Rails.obfuscatedLinkDisableSelector, 'ajax:stopped', Rails.enableObfuscatedLinkElement)

    Rails.delegate(document, Rails.obfuscatedLinkClickSelector, 'click', Rails.preventInsignificantClick)
    Rails.delegate(document, Rails.obfuscatedLinkClickSelector, 'click', Rails.handleDisabledElement)
    Rails.delegate(document, Rails.obfuscatedLinkClickSelector, 'click', Rails.handleConfirm)
    Rails.delegate(document, Rails.obfuscatedLinkClickSelector, 'click', Rails.disableObfuscatedLinkElement)
    Rails.delegate(document, Rails.obfuscatedLinkClickSelector, 'click', Rails.handleRemote)
    Rails.delegate(document, Rails.obfuscatedLinkClickSelector, 'click', Rails.handleMethod)

    EventUtil.delegateListeners('click keydown', '[data-obf]:not([data-confirm]):not([data-method]):not([data-remote]):not([disabled])', (e, link) => {
      if (e.type === 'keydown' && !['Enter'].includes(e.key)) return

      e.preventDefault()
      this.obfuscate(link)
    })
  }

  obfuscate(link) {
    const path = atob(link.dataset.obf)
    const target = !GlobalUtil.isBlank(link.target) ? link.target : '_self'

    window.open(path, target)
  }
}
