import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class HorizontalList {
  constructor () {
    this.classes = {
      container: '.o-js-slider-horizontal-list',
      item: '.o-js-slider-horizontal-list-item',
      nav: '.o-js-slider-horizontal-list-nav'
    }
    this.sliders = []
  }

  init () {
    window.addEventListener('load', e => this.initSliders())
  }

  initSliders () {
    GlobalUtil.forEach(document.querySelectorAll(this.classes.container), (index, slider) => {
      const sliderId = slider.dataset.sliderId

      this.sliders[sliderId] = {
        container: slider,
        items: slider.querySelectorAll(this.classes.item)
      }

      this.setCurrentItem(sliderId)
      this.setNavigation(sliderId)

      EventUtil.addListeners(slider, 'scroll', e => this.setCurrentItem(sliderId))
    })
  }

  setCurrentItem (sliderId) {
    const slider = this.sliders[sliderId]
    const scrollLeft = slider.container.scrollLeft

    GlobalUtil.forEach(slider.items, (index, item) => {
      item.classList.toggle('is-current', (scrollLeft >= item.offsetLeft - parseFloat(window.getComputedStyle(item).marginLeft) && scrollLeft < item.offsetLeft + item.offsetWidth + parseFloat(window.getComputedStyle(item).marginRight)))
    })
  }

  setNavigation (sliderId) {
    const slider = this.sliders[sliderId]
    const nav = document.querySelector(`${this.classes.nav}[data-slider-id="${slider.container.dataset.sliderId}"]`)

    if (nav) {
      const leftBtn = nav.querySelector('[data-action="left"]')
      const rightBtn = nav.querySelector('[data-action="right"]')

      EventUtil.addListeners([leftBtn, rightBtn], 'click keyup', (e, btn) => {
        if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
          this.move(sliderId, btn.dataset.action)
        }
      })
    }
  }

  move (sliderId, direction = 'right') {
    const slider = this.sliders[sliderId]
    const currentItem = Array.from(slider.items).find(item => item.classList.contains('is-current'))
    const currentItemIndex = Array.from(slider.items).indexOf(currentItem)
    let nextItemIndex

    if (direction === 'right') {
      nextItemIndex = currentItemIndex === Array.from(slider.items).length - 1 ? currentItemIndex : currentItemIndex + 1
    } else if (direction === 'left') {
      nextItemIndex = currentItemIndex === 0 ? currentItemIndex : currentItemIndex - 1
    }

    if (!GlobalUtil.isBlank(nextItemIndex) && nextItemIndex !== currentItemIndex) {
      const nextItem = Array.from(slider.items)[nextItemIndex]

      GlobalUtil.forEach(slider.items, (index, item) => item.classList.remove('is-current'))
      nextItem.classList.add('is-current')

      $(slider.container).animate({ scrollLeft: nextItem.offsetLeft - parseFloat(window.getComputedStyle(nextItem).marginLeft) }, 400)
    }
  }
}
