import GlobalUtil from '../Util/GlobalUtil'

export default class Radio {
  constructor () {
    this.inputs = document.querySelectorAll('.form-group.radio_buttons .radio input[type="radio"]')
  }

  init () {
    GlobalUtil.forEach(this.inputs, (index, input) => this.wrapInput(input))
  }

  wrapInput (input) {
    if (!input.nextElementSibling || !input.nextElementSibling.classList.contains('o-radio')) {
      const icon = document.createElement('div')

      icon.classList.add('o-radio')
      GlobalUtil.insertAfter(icon, input)
    }
  }
}
