import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class NumberInput {
  constructor () {
    this.customArrowsGroupClass = '.o-js-input-number-arrows'
    this.customArrowsBtnClass = '.o-js-input-number-arrow'
  }

  init () {
    EventUtil.delegateListeners('click keyup', this.customArrowsBtnClass, (e, el) => {
      if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
        const defaultStep = el.dataset.action === 'less' ? -1 : 1
        const group = el.closest(this.customArrowsGroupClass)
        const input = group.querySelector('input[type="number"]')
        const step = !GlobalUtil.isBlank(el.dataset.step) ? parseInt(el.dataset.step) : defaultStep

        this.setInputValue(input, parseInt(input.value) + step)
      }
    })
  }

  setInputValue (input, newValue) {
    if (!isNaN(newValue) && !input.disabled) {
      const inputMin = input.getAttribute('min')
      const inputMax = input.getAttribute('max')

      if (inputMin && newValue < inputMin) {
        newValue = inputMin
      } else if (inputMax && newValue > inputMax) {
        newValue = inputMax
      }

      input.value = newValue
      EventUtil.trigger(input, 'change')
    }
  }
}
