import EventUtil from '../Util/EventUtil'
import FormUtil from '../Util/FormUtil'
import GlobalUtil from '../Util/GlobalUtil'

import Captcha from './Captcha'
import Checkbox from './Checkbox'
import Datepicker from './Datepicker'
import Email from './Email'
import File from './File'
import NumberInput from './Number'
import Password from './Password'
import Phone from './Phone'
import Radio from './Radio'
import Select from './Select'

export default class Form {
  constructor () {
    this.captcha = new Captcha()
    this.checkbox = new Checkbox()
    this.datepicker = new Datepicker()
    this.email = new Email()
    this.file = new File()
    this.number = new NumberInput()
    this.password = new Password()
    this.phone = new Phone()
    this.radio = new Radio()
    this.select = new Select()
    this.inputsDisabled = document.querySelectorAll('input[disabled], select[disabled], textarea[disabled]')
    this.dropdowns = document.querySelectorAll('.o-js-form-dropdown')
    this.expandBlocks = document.querySelectorAll('.o-js-form-expand')
  }

  init () {
    // INIT INPUTS
    this.captcha.init()
    this.checkbox.init()
    this.datepicker.init()
    this.email.init()
    this.file.init()
    this.number.init()
    this.password.init()
    this.phone.init()
    this.radio.init()
    this.select.init()

    // LOOP ON DISABLED INPUTS
    GlobalUtil.forEach(this.inputsDisabled, (index, input) => FormUtil.checkInputDisabledState(input))

    // LOOP ON DROPDOWNS
    GlobalUtil.forEach(this.dropdowns, (index, dropdown) => {
      const trigger = dropdown.querySelector('.o-js-form-dropdown-trigger')
      const content = dropdown.querySelector('.o-js-form-dropdown-content')

      if (content.querySelector('.has-error')) {
        content.style.display = 'block'
        dropdown.classList.add('is-opened')
      }

      // Click
      EventUtil.addListeners(trigger, 'click keyup', e => {
        if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
          const triggerExpanded = trigger.getAttribute('aria-expanded')

          $(content).slideToggle(400)
          dropdown.classList.toggle('is-opened')
          if (triggerExpanded) trigger.setAttribute('aria-expanded', triggerExpanded === 'false')
        }
      })
    })

    // LOOP ON EXPAND BLOCKS
    GlobalUtil.forEach(this.expandBlocks, (index, block) => {
      const maxItems = block.dataset.expandMax
      const trigger = block.querySelector('.o-js-form-expand-trigger')
      const items = block.querySelectorAll('.o-js-form-expand-item')

      if (items.length <= maxItems) {
        GlobalUtil.forEach(items, (index, item) => { item.style.display = 'block' })
      } else {
        Array.from(items).slice(maxItems).forEach(item => { item.style.display = 'none' })
      }

      // Click
      EventUtil.addListeners(trigger, 'click keyup', e => {
        if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
          trigger.classList.toggle('is-active')
          this.toggleExpandBlock(block)
        }
      })
    })
  }

  toggleExpandBlock (block) {
    const maxItems = block.dataset.expandMax
    const trigger = block.querySelector('.o-js-form-expand-trigger')
    const items = block.querySelectorAll('.o-js-form-expand-item')

    if (trigger.classList.contains('is-active')) {
      GlobalUtil.forEach(items, (index, item) => { item.style.display = 'block' })
    } else {
      Array.from(items).slice(maxItems).forEach(item => { item.style.display = 'none' })
    }
  }
}
