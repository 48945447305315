import '../vendors/cocoon'

import EventUtil from './Util/EventUtil'
import FormUtil from './Util/FormUtil'

export default class Invitation {
  constructor () {
    this.list = document.querySelector('.o-js-invitations-list')
    this.listButtonAdd = document.querySelector('.o-js-invitations-list-add')
    this.listCheckboxSelf = document.querySelector('.o-js-invitations-list-checkbox-self')
  }

  init () {
    let self = this

    if (this.list) {
      this.checkListLimit()

      EventUtil.addListeners(this.listCheckboxSelf, 'change', e => this.checkListLimit())

      $(this.list)
        .on('cocoon:after-insert', function () { self.checkListLimit() })
        .on('cocoon:after-remove', function () { self.checkListLimit() })
    }
  }

  checkListLimit () {
    const limit = this.list.dataset.limit
    const invitations = this.list.querySelectorAll('.o-js-invitations-list-item')

    if (this.listCheckboxSelf.checked) {
      this.listButtonAdd.style.visibility = (invitations.length >= limit - 1) ? 'hidden' : 'visible'
    } else {
      this.listButtonAdd.style.visibility = (invitations.length >= limit) ? 'hidden' : 'visible'
      FormUtil.setInputDisabledState(this.listCheckboxSelf, invitations.length >= limit)
    }
  }
}
