import '../vendors/cocoon'

import EventUtil from './Util/EventUtil'
import GlobalUtil from './Util/GlobalUtil'

export default class Membership {
  constructor () {
    this.organizationForms = document.querySelectorAll('.o-js-membership-organization-form')
    this.switchOrganizationFormBtns = document.querySelectorAll('.o-js-membership-switch-organization-form')
    this.variantRadios = document.querySelectorAll('.o-js-membership-variant-radio')
    this.variantBnfAdditionalInput = document.querySelector('.o-js-membership-variant-bnf-additional')
  }

  init () {
    if (this.variantBnfAdditionalInput) {
      this.displayVariantBnfAdditional()
      EventUtil.addListeners(this.variantRadios, 'change', (e, radio) => this.displayVariantBnfAdditional())
    }

    if (this.organizationForms.length) {
      this.displayOrganizationForm()
    }

    EventUtil.addListeners(this.switchOrganizationFormBtns, 'click', (e, btn) => {
      e.preventDefault()
      this.switchOrganizationForm()
    })
  }

  displayVariantBnfAdditional () {
    const hasBnfRadiosChecked = Array.from(this.variantRadios).some(radio => radio.checked && radio.dataset.bnf === 'true')

    this.variantBnfAdditionalInput.style.display = hasBnfRadiosChecked ? 'block' : 'none'
  }

  getCurrentOrganizationForm (step) {
    let currentForm

    if (step && step === 'init') {
      GlobalUtil.forEach(this.organizationForms, (index, form) => {
        if (currentForm === undefined && form.querySelector('.has-error')) {
          currentForm = form
        }
      })
    }

    if (currentForm === undefined) {
      currentForm = Array.from(this.organizationForms).find(form => !form.classList.contains('o-hidden'))
    }

    return currentForm
  }

  displayOrganizationForm () {
    const currentForm = this.getCurrentOrganizationForm('init')
    const hiddenForm = Array.from(this.organizationForms).filter(form => form !== currentForm)[0]

    currentForm.classList.remove('o-hidden')
    hiddenForm.classList.add('o-hidden')

    GlobalUtil.forEach(currentForm.querySelectorAll('input, select, textarea'), (index, input) => {
      if (input.classList.contains('o-js-select-single')) {
        $(input).prop('disabled', false)
      } else {
        input.disabled = false
      }
    })

    GlobalUtil.forEach(hiddenForm.querySelectorAll('input, select, textarea'), (index, input) => {
      if (input.classList.contains('o-js-select-single')) {
        $(input).prop('disabled', true)
      } else {
        input.disabled = true
      }
    })
  }

  switchOrganizationForm () {
    const currentForm = this.getCurrentOrganizationForm()
    const hiddenForm = Array.from(this.organizationForms).filter(form => form !== currentForm)[0]

    $(currentForm).fadeOut(200, function() {
      currentForm.classList.add('o-hidden')

      GlobalUtil.forEach(currentForm.querySelectorAll('input, select, textarea'), (index, input) => {
        if (input.classList.contains('o-js-select-single')) {
          $(input).prop('disabled', true)
        } else {
          input.disabled = true
        }
      })

      GlobalUtil.forEach(hiddenForm.querySelectorAll('input, select, textarea'), (index, input) => {
        if (input.classList.contains('o-js-select-single')) {
          $(input).prop('disabled', false)
        } else {
          input.disabled = false
        }
      })

      $(hiddenForm).fadeIn(200, function() {
        hiddenForm.classList.remove('o-hidden')
      })
    })
  }
}
