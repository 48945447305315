import MicroModal from 'micromodal'
import Rails from '../Rails/ujs'

import CloseImage from '../../images/icons/stroke-cross-circle-black.svg'

import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class Confirm {
  constructor () {
    this.modalId = 'o-page-modal'
    this.modalConfirmCallbackClass = 'o-js-modal-confirm-callback'
  }

  init () {
    const allowAction = target => {
      let el = target

      if (GlobalUtil.isBlank(el.dataset.confirm)) {
        el = el.closest('[data-confirm]')
        if (!el) return true
      }

      show(el)
      return false
    }

    const confirmed = el => {
      if (el.dataset.remote === 'true' || el.dataset.confirmForceClose === 'true') MicroModal.close(this.modalId)

      el.removeAttribute('data-confirm')

      let a = el.cloneNode(true)
      a.classList.add(this.modalConfirmCallbackClass)
      document.body.appendChild(a)

      let modalConfirmCallback = document.querySelector('.' + this.modalConfirmCallbackClass)

      modalConfirmCallback.addEventListener('click', Rails.handleMethod)
      modalConfirmCallback.click()
      modalConfirmCallback.remove()
    }

    const handleConfirm = e => {
      if (!allowAction(e.target)) Rails.stopEverything(e)
    }

    const show = (el) => {
      const modalMessage = el.dataset.confirm
      const modalTitle = el.dataset.confirmModalTitle
      const modalCancelBtn = el.dataset.confirmModalCancelButton
      const modalCloseBtn = el.dataset.confirmModalCloseButton
      const modalSubmitBtn = el.dataset.confirmModalSubmitButton

      const modalHtml = `
        <div class="o-page-modal__header">
          <h2 id="o-page-modal-title" class="o-page-modal__title">${modalTitle}</h2>
          <button class="o-page-modal__close" data-micromodal-close>${modalCloseBtn} <img src="${CloseImage}" class="o-icon" alt="" data-micromodal-close /></button>
        </div>
        <div class="o-page-modal__content">
          <p class="o-page-modal__confirm-message">${modalMessage}</p>

          <div class="o-page-modal__actions">
            <button type="button" class='o-action o-cancel' data-micromodal-close>${modalCancelBtn}</button>
            <button type="button" class="o-action o-submit">${modalSubmitBtn}</button>
          </div>
        </div>`

      document.querySelector('#o-page-modal-container').innerHTML = modalHtml

      MicroModal.show(this.modalId)

      const modal = document.getElementById(this.modalId)
      EventUtil.addListeners(modal.querySelector('.o-submit'), 'click', e => confirmed(el))
    }

    Rails.delegate(document, '[data-confirm]', 'click', handleConfirm)

    MicroModal.init({
      disableScroll: true,
      disableFocus: true
    })
  }
}
