class FormUtil {
  checkInputDisabledState(input, disabled = input.disabled) {
    const parent = input.closest('.form-group')
    if (parent) parent.classList.toggle('disabled', disabled)
    input.classList.toggle('disabled', disabled)
  }

  displayInputStatus (input, status) {
    if (status) {
      input.parentNode.classList.toggle('is-valid', status === 'valid')
      input.parentNode.classList.toggle('is-invalid', status === 'invalid')
    } else {
      input.parentNode.classList.remove('is-valid', 'is-invalid')
    }
  }

  getInputsToCheck (inputs) {
    return Array.from(inputs).filter(r => r.classList.contains('o-js-input-check'))
  }

  setInputDisabledState(input, state) {
    input.disabled = state
    this.checkInputDisabledState(input)
  }

  serialize (form) {
    const serialized = []

    // Loop through each field in the form
    for (let i = 0; i < form.elements.length; i++) {
      const field = form.elements[i]

      // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
      if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue

      // If a multi-select, get all selections
      if (field.type === 'select-multiple') {
        for (let n = 0; n < field.options.length; n++) {
          if (!field.options[n].selected) continue
          serialized.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[n].value))
        }
      } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) { // Convert field data to a query string
        serialized.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value))
      }
    }

    return serialized.join('&')
  }
}

const instance = new FormUtil()
Object.freeze(instance)

export default instance
