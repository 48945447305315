import 'select2'
import 'select2/dist/css/select2'

import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class Select {
  constructor () {
    this.select2SharedOptions = { width: '100%' }
  }

  init () {
    let self = this

    self.initSelect2()

    $('#advanced_searches').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
      self.initSelect2()
    })

    $(this.selectsSingle).on('select2:open', function(e) {
        self.setSelect2Autofocus()
    })

    $(this.selectsMultiple).on('select2:open', function(e) {
        self.setSelect2Autofocus()
    })
  }

  initSelect2 () {
    this.getSelects()

    // SINGLE
    GlobalUtil.forEach(this.selectsSingle, (index, select) => this.setSingle(select))

    // MULTIPLE
    GlobalUtil.forEach(this.selectsMultiple, (index, select) => this.setMultiple(select))

    // BOTH
    this.setSharedEvents()
  }

  getSelects () {
    this.selectsSingle = document.querySelectorAll('.o-js-select-single')
    this.selectsMultiple = document.querySelectorAll('.o-js-select-multiple')
  }

  setSingle (select) {
    let select2SingleOptions = {
      ...this.select2SharedOptions,
      dropdownParent: select.parentElement,
      minimumResultsForSearch: select.dataset.search === 'true' ? 0 : Infinity
    }

    let options = $.extend({}, select2SingleOptions, this.setSharedOptions(select))
    $(select).select2(options)
  }

  setMultiple (select) {
    let select2MultipleOptions = {
      ...this.select2SharedOptions,
      dropdownParent: select.parentElement
    }

    let options = $.extend({}, select2MultipleOptions, this.setSharedOptions(select))
    $(select).select2(options)
  }

  setSharedOptions (select) {
    let options = {}
    let source = select.dataset.source

    if (source !== undefined) {
      options = {
        minimumInputLength: 2,
        ajax: {
          url: source,
          dataType: 'json',
          data: function (params) {
            return {
              search: params.term,
            }
          },
          processResults: function (data) {
            return {
              results: data.map(obj => {
                obj.text = obj.text || obj.to_label
                return obj
              })
            }
          }
        }
      }
    }

    return options
  }

  setSharedEvents () {
    // Fix label
    GlobalUtil.forEach(document.querySelectorAll('.select2.select2-container'), (index, el) => {
      EventUtil.addListeners(el.parentElement, 'click', e => e.preventDefault())
    })

    // Open single on focus
    EventUtil.delegateListeners('focus', '.form-group:not(.multi-select) .select2-selection.select2-selection--single', (e, el) => {
      $(el).closest('.select2-container').siblings('select:enabled').select2('open')
    }, { options: true })

    // Steal focus during close (only capture once)
    $('.form-group:not(.multi-select) select.select2').on('select2:closing', function (e) {
      $(e.target).data('select2').$selection.one('focus focusin', function (e) {
        e.stopPropagation()
      })
    })
  }

  setSelect2Autofocus() {
    let searchFields = document.querySelectorAll(".select2-container--open .select2-search__field")
    searchFields[searchFields.length - 1].focus()
  }
}
