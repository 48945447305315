import Cookies from 'js-cookie'

import EventUtil from '../Util/EventUtil'

export default class Locale {
  constructor () {
    this.classes = {
      trigger: '.o-js-switch-locale'
    }
  }

  init () {
    EventUtil.delegateListeners('click', this.classes.trigger, (e, trigger) => {
      e.preventDefault()

      Cookies.set('locale', trigger.dataset.locale, { expires: 365 })

      window.location.href = trigger.href
    })
  }
}
