import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class Pagination {
  constructor () {
    this.classes = {
      default: '.pagination'
    }
  }

  init () {
    window.addEventListener('load', e => {
      GlobalUtil.forEach(document.querySelectorAll(this.classes.default), (index, pagination) => {
        this.setMobileFormat(pagination)
        EventUtil.addListeners(window, 'resize', e => this.setMobileFormat(pagination))
      })
    })
  }

  setMobileFormat (pagination) {
    const pages = pagination.querySelectorAll('.page:not(.gap)')
    const previousBtn = pagination.querySelector('.control.prev')
    const nextBtn = pagination.querySelector('.control.next')

    GlobalUtil.forEach(pages, (index, page) => page.classList.remove('is-visible'))
    if (previousBtn) previousBtn.classList.remove('is-hidden')
    if (nextBtn) nextBtn.classList.remove('is-hidden')

    if (window.innerWidth >= 480 && window.innerWidth < 1280) {
      const currentPage = Array.from(pages).find(page => page.classList.contains('current'))
      const firstPreviousCurrentPage = Array.from(pages).find(page => page === currentPage.previousElementSibling)
      const firstNextCurrentPage = Array.from(pages).find(page => page === currentPage.nextElementSibling)

      if (firstPreviousCurrentPage) {
        const secondPreviousCurrentPage = Array.from(pages).find(page => page === firstPreviousCurrentPage.previousElementSibling)

        firstPreviousCurrentPage.classList.add('is-visible')
        if (previousBtn) previousBtn.classList.add('is-hidden')
        if (secondPreviousCurrentPage && window.innerWidth >= 768) secondPreviousCurrentPage.classList.add('is-visible')
      }

      if (firstNextCurrentPage) {
        const secondNextCurrentPage = Array.from(pages).find(page => page === firstNextCurrentPage.nextElementSibling)

        firstNextCurrentPage.classList.add('is-visible')
        if (nextBtn) nextBtn.classList.add('is-hidden')
        if (secondNextCurrentPage && window.innerWidth >= 768) secondNextCurrentPage.classList.add('is-visible')
      }
    }
  }
}
