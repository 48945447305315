class ActionUtil {
  focusableQuery () {
    return 'a[href]:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex], iframe, area[href]'
  }

  scrollTo (top, duration = 1000, includeHeader = true, extraMargin = 40) {
    if (includeHeader) top -= document.querySelector('.o-js-header').offsetHeight

    const scrollTop = top - extraMargin

    if (duration === 0) {
      window.scrollTo(0, scrollTop)
    } else {
      $('html, body').animate({ scrollTop: scrollTop }, duration)
    }
  }
}

const instance = new ActionUtil()
Object.freeze(instance)

export default instance
