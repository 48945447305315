class GlobalUtil {
  /**
   * @function forEach
   * Used to loop and NodeList (querySelectorAll items)
   */
  forEach (array, callback, scope) {
    if (array) {
      for (let i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i])
      }
    }
  }

  getAppLocale () {
    const lang = document.querySelector('html').getAttribute('lang')

    return this.isBlank(lang) ? 'fr' : lang
  }

  getRandomInt (min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)

    return Math.floor(Math.random() * (max - min)) + min
  }

  isBlank (value) {
    return value === null || value === undefined || value.length === 0
  }

  isVisible (el) {
    return el => !!el && !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length)
  }

  viewportOffset (el) {
    const rect = el.getBoundingClientRect()
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft
    const scrollTop = window.scrollY || document.documentElement.scrollTop

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  wrap (el, wrapper = document.createElement('div')) {
    el.parentNode.insertBefore(wrapper, el)
    wrapper.appendChild(el)
  }

  insertAfter (el, reference) {
    reference.parentNode.insertBefore(el, reference)
    reference.parentNode.insertBefore(reference, el)
  }
}

const instance = new GlobalUtil()
Object.freeze(instance)

export default instance
