import EventUtil from '../Util/EventUtil'
import FormUtil from '../Util/FormUtil'
import GlobalUtil from '../Util/GlobalUtil'

import Confirmation from './Confirmation'

export default class Password {
  constructor () {
    this.inputs = document.querySelectorAll('input.password')
    this.inputsStrengthIndicator = Array.from(this.inputs).filter(r => r.classList.contains('o-js-password-strength-input'))
    this.inputsConfirmation = Confirmation.getInputs(this.inputs)
    this.inputsToCheck = FormUtil.getInputsToCheck(this.inputs)
  }

  init () {
    // SWITCH EYE ICON
    GlobalUtil.forEach(this.inputs, (index, input) => this.addSwitchIcon(input))

    // STRENGTH INDICATOR
    GlobalUtil.forEach(this.inputsStrengthIndicator, (index, input) => {
      const checker = new PasswordStrengthChecker(input)

      checker.init()
    })

    // INPUTS TO CHECK VALIDITY
    EventUtil.addListeners(this.inputsToCheck, 'keyup', (e, input) => this.checkInput(input))

    // INPUTS CONFIRMATION
    GlobalUtil.forEach(this.inputsConfirmation, (index, input) => {
      Confirmation.listenInputMatch(input, () => this.checkInput(input))
    })
  }

  addSwitchIcon (input) {
    const icon = document.createElement('i')

    icon.classList.add('input-icon', 'far', 'fa-eye')
    input.after(icon)

    EventUtil.addListeners(icon, 'click', e => {
      if (input.type === 'password') {
        icon.classList.remove('fa-eye')
        icon.classList.add('fa-eye-slash')
        input.type = 'text'
      } else {
        icon.classList.remove('fa-eye-slash')
        icon.classList.add('fa-eye')
        input.type = 'password'
      }
    })
  }

  checkInput (input) {
    const status = this.validateInput(input)

    FormUtil.displayInputStatus(input, status)
  }

  validateInput (input) {
    if (!input.value) return
    if (input.classList.contains('o-js-input-confirmation') && Confirmation.validateMatch(input) !== 'valid') return Confirmation.validateMatch(input)
    if (input.value.length < input.dataset.min) return 'invalid'
    return 'valid'
  }
}

// --------------------------------------------------- //
//                                                     //
// --------------------------------------------------- //

class PasswordStrengthChecker {
  constructor (input) {
    this.input = input
    this.minimumLength = input.dataset.min
    this.label = input.closest('.form-group').querySelector('.o-js-password-strength-label')
    this.names = JSON.parse(input.dataset.labels)
    this.colors = { bad: 'red', medium: '#F9B033', good: '#4AB83E' }
  }

  init () {
    EventUtil.addListeners(this.input, 'keyup', e => this.displayStrength())
  }

  displayStrength () {
    const strength = this.getStrength()

    this.label.innerHTML = strength ? this.names[strength] : ''
    this.label.style.color = strength ? this.colors[strength] : ''
  }

  getStrength () {
    const score = this.calculateScore()

    if (score === -1) return
    if (score < 40) return 'bad'
    if (score < 70) return 'medium'
    return 'good'
  }

  calculateScore () {
    let score = 0

    // empty
    if (this.input.value.length === 0) return -1

    // is too short
    if (this.input.value.length < this.minimumLength) return 0

    // length
    score += this.input.value.length * 5

    // char repetition
    score -= this.getCharRepetition() * 5

    // has 2 numbers
    if (this.input.value.match(/(.*[0-9].*[0-9])/)) score += 5

    // has at least 2 symbols
    let symbols = '.*[!,@,#,$,%,^,&,*,?,_,~]'
    symbols = new RegExp('(' + symbols + symbols + ')')
    if (this.input.value.match(symbols)) score += 5

    if (this.input.value.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) score += 15 // has UPPER and lower chars
    if (this.input.value.match(/([a-zA-Z])/) && this.input.value.match(/([0-9])/)) score += 15 // has number and chars
    if (this.input.value.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && this.input.value.match(/([0-9])/)) score += 15 // has number and symbol
    if (this.input.value.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && this.input.value.match(/([a-zA-Z])/)) score += 15 // has char and symbol
    if (this.input.value.match(/^\w+$/) || this.input.value.match(/^\d+$/)) score -= 20 // is just numbers or chars

    if (score > 100) {
      score = 100
    } else if (score < 0) {
      score = 0
    }

    return score
  }

  getCharRepetition() {
    let count = 0

    if (!this.input.value) return 0

    for (let i = 1; i < this.input.value.length; i++) {
      if (this.input.value.charAt(i) === this.input.value.charAt(i - 1)) {
        count++
      }
    }

    return count
  }
}
