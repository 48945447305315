import 'intersection-observer'
import lozad from 'lozad'

import GlobalUtil from '../Util/GlobalUtil'

export default class LazyLoading {
  constructor () {
    this.classes = {
      default: '.o-js-lazy',
      noscript: '.o-js-lazy-noscript'
    }
    this.defaultMargin = '200px 0px'
  }

  init () {
    const observer = lozad(this.classes.default, {
      rootMargin: this.defaultMargin
    })

    observer.observe()

    // Remove noscript tags
    GlobalUtil.forEach(document.querySelectorAll(this.classes.noscript), (index, block) => block.parentNode.removeChild(block))
  }
}
