export default class Polyfill {
  init () {
    this.after()
    this.matches()
    this.closest()
    this.nextElementSibling()
  }

  after () {
    (function (arr) {
      arr.forEach(function (item) {
        if (item.hasOwnProperty('after')) {
          return
        }

        Object.defineProperty(item, 'after', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function after() {
            let argArr = Array.prototype.slice.call(arguments)
            let docFrag = document.createDocumentFragment()

            argArr.forEach(function (argItem) {
              var isNode = argItem instanceof Node
              docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)))
            })

            this.parentNode.insertBefore(docFrag, this.nextSibling)
          }
        })
      })
    })([Element.prototype, CharacterData.prototype, DocumentType.prototype])
  }

  closest () {
    if (!Element.prototype.closest) {
      Element.prototype.closest = function(s) {
        var el = this
        if (!document.documentElement.contains(el)) return null
        do {
          if (el.matches(s)) return el
          el = el.parentElement || el.parentNode
        } while (el !== null && el.nodeType == 1)
        return null
      }
    }
  }

  matches () {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector
    }
  }

  nextElementSibling () {
    if (!('nextElementSibling' in document.documentElement)) {
      Object.defineProperty(Element.prototype, 'nextElementSibling', {
        get: function () {
          let e = this.nextSibling
          while (e && 1 !== e.nodeType) {
            e = e.nextSibling
          }
          return e
        }
      })
    }
  }
}
