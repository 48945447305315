import Cart from './Cart'
import Checkout from './Checkout'

export default class Commerce {
  constructor () {
    this.cart = new Cart()
    this.checkout = new Checkout()
  }

  init () {
    this.cart.init()
    this.checkout.init()
  }
}
