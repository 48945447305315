import HomeBanner from './HomeBanner'
import HomeNotice from './HomeNotice'
import HomeService from './HomeService'
import HorizontalList from './HorizontalList'

export default class Slider {
  constructor () {
    this.homeBanner = new HomeBanner()
    this.homeNotice = new HomeNotice()
    this.homeService = new HomeService()
    this.horizontalList = new HorizontalList()
  }

  init () {
    this.homeBanner.init()
    this.homeNotice.init()
    this.homeService.init()
    this.horizontalList.init()
  }
}
