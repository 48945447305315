import Device from '../Util/Device'

export default class FontLoader {
  constructor () {
    this.fontType = Device.getMSBrowserVersion() && Device.getMSBrowserVersion() < 14 ? 'woff' : 'woff2'
    this.fontsToLoad = [
      { href: `./fonts/Canaro/Canaro-Black.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-BlackItalic.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-Bold.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-BoldItalic.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-Book.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-BookItalic.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-ExtraBold.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-ExtraBoldItalic.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-ExtraLight.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-ExtraLightItalic.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-Light.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-LightItalic.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-Medium.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-MediumItalic.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-SemiBold.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-SemiBoldItalic.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-Thin.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/Canaro/Canaro-ThinItalic.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Bd.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-BdCn.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-BdIt.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Blk.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-BlkIt.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Hv.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-HvIt.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-It.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Lt.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-LtIt.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Md.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-MdIt.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Roman.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Th.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-ThIt.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-UltLt.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-UltLtIt.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./vendors/fontawesome-pro/fa-brands-400.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./vendors/fontawesome-pro/fa-light-300.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./vendors/fontawesome-pro/fa-regular-400.${this.fontType}`, type: `font/${this.fontType}` },
      { href: `./vendors/fontawesome-pro/fa-solid-900.${this.fontType}`, type: `font/${this.fontType}` }
    ]
  }

  init () {
    const fonts = require.context('../../', true, /\.(eot|ttf|woff|woff2|otf)$/i)
    const fontPath = (name) => fonts(name, true)
    const fontsFragment = document.createDocumentFragment()

    this.fontsToLoad.forEach((font) => {
      let fontLink = document.createElement('link')

      fontLink.rel = 'preload'
      fontLink.type = font.type
      fontLink.href = fontPath(font.href)
      fontLink.as = 'font'
      fontLink.crossOrigin = 'anonymous'

      fontsFragment.appendChild(fontLink)
    })

    document.head.appendChild(fontsFragment)
  }
}
