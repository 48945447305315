import Swiper from 'swiper'
import { Autoplay, Navigation } from 'swiper/modules'
import GlobalUtil from '../Util/GlobalUtil'

export default class HomeService {
  constructor () {
    this.classes = {
      element: '.o-js-slider-home-service'
    }

    this.sliders = []

    Swiper.use([Autoplay, Navigation])
  }

  init () {
    window.addEventListener('load', e => this.initSliders())
  }

  initSliders() {
    GlobalUtil.forEach(document.querySelectorAll(this.classes.element), (index, element) => {
      const slider = element.querySelector('.swiper')
      const sliderId = slider.id
      const prevEl = element.querySelector('.swiper-button-prev')
      const nextEl = element.querySelector('.swiper-button-next')

      this.sliders[sliderId] = new Swiper(slider, {
        spaceBetween: 16,
        loop: true,
        speed: 500,
        autoplay: {
          delay: 4000,
        },
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          480: {
            slidesPerView: 2,
          },
          768: {
            spaceBetween: 30,
            slidesPerView: 2,
          },
        }
      })
    })
  }
}
