import Rails from '../Rails/ujs'

import ActionUtil from '../Util/ActionUtil'
import EventUtil from '../Util/EventUtil'
import FormUtil from '../Util/FormUtil'
import GlobalUtil from '../Util/GlobalUtil'

import Checkbox from '../Form/Checkbox'
import Header from './Header'
import Loader from '../Tool/Loader'
import Radio from '../Form/Radio'
import Select from '../Form/Select'
import Toggle from '../Action/Toggle'

export default class Search {
  constructor () {
    this.header = new Header()
    this.filterBox = document.querySelector('.o-js-search-box-filter')
    this.searchBox = document.querySelector('.o-js-search-box')
    this.advancedBox = document.querySelector('.o-js-advanced-search')
    this.defaultFilterGroupStep = 5
    this.form = document.querySelector('.o-js-search-form')
    this.order = document.querySelector('.o-js-switch-order')

    if (this.form) {
      this.formName = this.form.dataset.formName
    }
  }

  init () {
    let self = this

    if (this.filterBox) {
      this.setFilterBox()
    }

    if (this.searchBox) {
      this.search()
    }

    if (this.order) {
      EventUtil.delegateListeners('change', '.o-js-switch-order', (e, el) => {
        Rails.fire(document.querySelector('.o-js-search-form'), 'submit')
      })
    }

    if (this.advancedBox) {
      this.advancedSearch()

      // Toggle advanced search event
      EventUtil.delegateListeners('click keyup', '.o-js-advanced-search-toggle', (e, el) => {
        if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
          document.querySelector('.o-js-advanced-search-content').classList.toggle('is-opened')
          self.toggleAdvancedSearch()
        }
      })
    }
  }

  setFilterBox () {
    let self = this

    // Open side
    EventUtil.delegateListeners('click keyup', '.o-js-search-box-filter-side-open', (e, el) => {
      if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
        let dataId = el.closest('.o-js-filter').dataset.id

        GlobalUtil.forEach(document.querySelectorAll(`.o-js-children-filter[data-parent-id="${dataId}"]`), (index, child) => child.classList.remove('is-hidden'))
        document.querySelector('.o-js-search-box-filter').classList.add('has-scroll-disabled')
        document.querySelector('.o-js-search-box-filter-side').classList.add('is-opened')

        if (e.keyCode === 13 || e.keyCode === 32) {
          setTimeout(() => document.querySelector('.o-js-children-filter:not(.is-hidden) input[type="checkbox"]').focus(), 450)
        }

        EventUtil.delegateListeners('keyup', '.o-js-children-filter:not(.is-hidden) .o-js-children-filter-item:last-child input[type="checkbox"]', (e, el) => {
          if (e.keyCode === 9) {
            e.preventDefault()
            document.querySelector('.o-js-search-box-filter-validation').focus()
            return false
          }
        })
      }
    })

    // Close side
    EventUtil.delegateListeners('click keyup', '.o-js-search-box-filter-side-close', (e, el) => {
      if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
        document.querySelector('.o-js-search-box-filter').classList.remove('has-scroll-disabled')
        document.querySelector('.o-js-search-box-filter-side').classList.remove('is-opened')
        GlobalUtil.forEach(document.querySelectorAll('.o-js-children-filter'), (index, child) => child.classList.add('is-hidden'))

        if (e.keyCode === 13 || e.keyCode === 32) {
          document.querySelector('.o-js-search-box-filter input[type="checkbox"]').focus()
        }
      }
    })

    EventUtil.delegateListeners('click', '.o-js-search-box-filter-validation', (e, el) => Rails.fire(document.querySelector('.o-js-search-form'), 'submit'))

    // Init expand filter groups
    this.setFilterBoxGroups()

    // Shrink filter group
    EventUtil.delegateListeners('click keyup', '.o-js-filter-group-shrink', (e, el) => {
      if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
        self.toggleFilterGroup(el.closest('.o-js-filter-group'), 'shrink', e)
      }
    })

    // Expand filter group
    EventUtil.delegateListeners('click keyup', '.o-js-filter-group-expand', (e, el) => {
      if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
        self.toggleFilterGroup(el.closest('.o-js-filter-group'), 'expand', e)
      }
    })

    // Submit form on checkboxes change
    EventUtil.delegateListeners('change', '.o-js-search-box-filter input[type="checkbox"]', (e, el) => {
      const filterChildren = el.closest('.o-js-children-filter')

      // Parent checkboxes
      if (el.closest('.o-js-filter-group-with-children')) {
        const children = document.querySelectorAll(`.o-js-children-filter[data-parent-id="${el.value}"] input[type="checkbox"]`)

        GlobalUtil.forEach(children, (index, child) => {
          child.checked = el.checked
        })

        if (!el.checked) {
          const hiddenElements = document.querySelectorAll(`.o-js-hidden-checkbox[data-parent-id="${el.value}"]`)

          GlobalUtil.forEach(hiddenElements, (index, element) => {
            element.remove()
          })
        }
      }

      // Child checkboxes
      if (filterChildren) {
        const children = filterChildren.querySelectorAll('input[type="checkbox"]')
        const checkedChildren = filterChildren.querySelectorAll('input[type="checkbox"]:checked')
        const parent = document.querySelector(`.o-js-filter-group-with-children .o-js-filter[data-id="${filterChildren.dataset.parentId}"]`)

        parent.querySelector('input[type="checkbox"]').checked = children.length === checkedChildren.length
      } else {
        Rails.fire(document.querySelector('.o-js-search-form'), 'submit')
      }
    })

    // Clear filters (all or one by one)
    EventUtil.delegateListeners('click', '.o-js-side-filter, .o-js-clear-filters', (e, el) => {
      e.preventDefault()

      if (el.classList.contains('o-js-clear-filters')) {
        document.querySelector('.o-js-search-form').reset()
      } else {
        const dataId = el.querySelector('a').dataset.id
        self.clearFilter(dataId)
      }
    })

    // Reset filters
    EventUtil.delegateListeners('reset', '.o-js-search-form', (e, el) => {
      e.preventDefault()

      GlobalUtil.forEach(el.querySelectorAll('input[type="checkbox"]'), (index, input) => {
        input.checked = false
      })

      GlobalUtil.forEach(el.querySelectorAll('input[type="text"], input[type="hidden"]'), (index, input) => {
        input.value = ''
      })

      GlobalUtil.forEach(el.querySelectorAll('#advanced_searches .nested-fields'), (index, field) => field.remove())

      Rails.fire(document.querySelector('.o-js-search-form'), 'submit')
    })
  }

  setFilterBoxGroups () {
    let self = this

    GlobalUtil.forEach(document.querySelectorAll('.o-js-filter-group'), (index, group) => {
      const filters = group.querySelectorAll('.o-js-filter')
      const trigger = group.querySelector('.o-js-filter-group-toggle')
      const step = !GlobalUtil.isBlank(group.dataset.step) ? group.dataset.step : self.defaultFilterGroupStep

      if (step !== 'infinite' && filters.length > step) {
        if (trigger.classList.contains('is-active')) {
          GlobalUtil.forEach(filters, (index, filter) => {
            filter.style.display = 'block'
          })
        } else {
          Array.from(filters).slice(step).forEach(filter => {
            filter.style.display = 'none'
          })
        }
      } else {
        group.style.display = 'block'
        trigger.style.display = 'none'
      }
    })
  }

  toggleFilterGroup (group, type, e = undefined) {
    const filters = group.querySelectorAll('.o-js-filter')
    const activeFilters = Array.from(filters).filter(filter => filter.style.display !== 'none')
    const step = !GlobalUtil.isBlank(group.dataset.step) ? group.dataset.step : this.defaultFilterGroupStep
    const trigger = group.querySelector('.o-js-filter-group-toggle')
    const triggerExpand = trigger.querySelector('.o-js-filter-group-expand')
    const triggerShrink = trigger.querySelector('.o-js-filter-group-shrink')

    if (type === 'shrink') {
      // Shrink
      activeFilters.length -= activeFilters.length - step
    } else {
      // Expand
      if (activeFilters.length > filters.length) {
        activeFilters.length = filters.length
      } else {
        activeFilters.length += step
      }
    }

    // Hide filters
    GlobalUtil.forEach(filters, (index, filter) => {
      filter.style.display = 'none'
    })

    // Display active filters
    Array.from(filters).slice(0, activeFilters.length).forEach(filter => {
      filter.style.display = 'block'
    })

    // Show / hide trigger buttons
    if (activeFilters.length <= step) {
      triggerShrink.style.display = 'none'
      triggerExpand.style.display = 'block'

      if (e && (e.keyCode === 13 || e.keyCode === 32)) {
        triggerExpand.focus()
      }
    } else if (activeFilters.length >= filters.length) {
      triggerShrink.style.display = 'block'
      triggerExpand.style.display = 'none'

      if (e && (e.keyCode === 13 || e.keyCode === 32)) {
        triggerShrink.focus()
      }
    } else {
      triggerShrink.style.display = 'block'
      triggerExpand.style.display = 'block'
    }
  }

  search () {
    let self = this

    EventUtil.delegateListeners('submit', '.o-js-search-form-container form', (e, el) => {
      e.preventDefault()

      Loader.show()

      Rails.ajax({
        url: el.getAttribute('action'),
        type: 'GET',
        dataType: 'script',
        data: FormUtil.serialize(el),
        success: data => {
          self.setFilterBoxGroups()

          if (this.advancedBox) {
            self.advancedSearch()
          }

          const checkbox = new Checkbox()
          const radio = new Radio()
          const select = new Select()
          const toggle = new Toggle()
          const toolbar = document.querySelector('.o-js-search-toolbar')

          checkbox.init()
          radio.init()
          select.init()
          toggle.init()

          Loader.hide()

          if (toolbar) {
            ActionUtil.scrollTo(toolbar.offsetTop, 600)
          }

          // Close header side filter
          if (this.header.toggleFilterButton) {
            this.header.toggleFilterButton.classList.remove('is-active')
            this.header.closeSide(this.filterBox)
          }
        }
      })
    })
  }

  advancedSearch () {
    let self = this
    const hasInputsFilled = Array.from(document.querySelectorAll('.o-js-advanced-search-content input:not([type="hidden"]):not([type="submit"]), .o-js-advanced-search-content select')).some(item => !GlobalUtil.isBlank(item.value))

    if (hasInputsFilled) self.showAdvancedSearch()

    EventUtil.delegateListeners('change', '.advanced-search-type', (e, el) => {
      const value = el.value

      GlobalUtil.forEach(el.closest('.nested-fields').querySelectorAll('.o-js-advanced-search-id'), (index, inputContainer) => {
        inputContainer.classList.add('o-js-advanced-search-id-hide')

        GlobalUtil.forEach(inputContainer.querySelectorAll('input'), (index, input) => {
          input.disabled = true
        })
      })

      GlobalUtil.forEach(el.closest('.nested-fields').querySelectorAll(`.advanced_${value}`), (index, input) => {
        input.disabled = false
        input.closest('.o-js-advanced-search-id').classList.remove('o-js-advanced-search-id-hide')
      })
    })

    EventUtil.trigger(document.querySelectorAll('.advanced-search-type'), 'change')
    self.removeFirstAdvancedOperator(document.querySelector('#advanced_searches'))

    $('#advanced_searches').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
      self.removeFirstAdvancedOperator(this)
      $(insertedItem).find(ActionUtil.focusableQuery()).first().focus()
    })
  }

  showAdvancedSearch () {
    const content = document.querySelector('.o-js-advanced-search-content')
    content.classList.add('is-opened')
    content.style.display = 'block'
    document.querySelector('.o-js-advanced-search-toggle').classList.add('is-active')
  }

  toggleAdvancedSearch () {
    const content = document.querySelector('.o-js-advanced-search-content')

    if (content.classList.contains('is-opened')) {
      $(content).slideDown(300)
      document.querySelector('.o-js-advanced-search-toggle').classList.add('is-active')
    } else {
      $(content).slideUp(300)
      document.querySelector('.o-js-advanced-search-toggle').classList.remove('is-active')
    }
  }

  clearFilter (filterId) {
    const filter = document.querySelector(`#${this.formName}_${filterId}`)

    if (filter) {
      if (filter.getAttribute('type') === 'checkbox') {
        const filterChildren = filter.closest('.o-js-children-filter')
        filter.checked = false
        EventUtil.trigger(filter, 'change')

        if (filterChildren) {
          Rails.fire(document.querySelector('.o-js-search-form'), 'submit')
        }
      } else {
        filter.value = ''
        Rails.fire(document.querySelector('.o-js-search-form'), 'submit')
      }
    } else if (filterId.includes('advanced_searches')) {
      let index = filterId.split('_')[filterId.split('_').length - 1]

      Array.from(document.querySelectorAll('#advanced_searches .nested-fields'))[index].remove()
      Rails.fire(document.querySelector('.o-js-search-form'), 'submit')
    }
  }

  removeFirstAdvancedOperator (advancedSearch) {
    GlobalUtil.forEach(advancedSearch.querySelectorAll('.nested-fields'), (index, field) => {
      const advancedOperator = field.querySelector('.o-js-advanced-operator')
      if (index === 0 && advancedOperator) advancedOperator.remove()
    })
  }
}
