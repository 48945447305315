import EventUtil from '../Util/EventUtil'

class Confirmation {
  getInputs (inputs) {
    return Array.from(inputs).filter(r => r.classList.contains('o-js-input-confirmation'))
  }

  getInputMatch (inputConfirmation) {
    return document.querySelector(`.o-js-input-to-confirm[data-id="${inputConfirmation.dataset.id}"]`)
  }

  listenInputMatch (inputConfirmation, callback) {
    const inputMatch = this.getInputMatch(inputConfirmation)

    EventUtil.addListeners(inputMatch, 'keyup', e => {
      if (callback) callback()
    })
  }

  validateMatch (inputConfirmation) {
    const inputMatch = this.getInputMatch(inputConfirmation)

    if (!inputMatch || !inputMatch.value || !inputConfirmation.value) return
    return inputConfirmation.value === inputMatch.value ? 'valid' : 'invalid'
  }
}

const instance = new Confirmation()
Object.freeze(instance)

export default instance
