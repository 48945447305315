import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

import GlobalUtil from '../Util/GlobalUtil'

export default class Tooltip {
  constructor () {
    this.triggers = document.querySelectorAll('[data-tooltip]')
  }

  init () {
    GlobalUtil.forEach(this.triggers, (index, trigger) => {
      if (GlobalUtil.isBlank(trigger.dataset.tooltipDevice) || (trigger.dataset.tooltipDevice === 'desktop' && window.innerWidth >= 1024) || (trigger.dataset.tooltipDevice === 'mobile' && window.innerWidth < 1024)) {
        this.set(trigger)
      }
    })
  }

  set (trigger) {
    let TooltipJsOptions = {
      theme: 'iif',
      content: trigger.dataset.tooltip,
    }

    if (!GlobalUtil.isBlank(trigger.dataset.html)) {
      TooltipJsOptions.allowHTML = trigger.dataset.html
    }

    tippy(trigger, TooltipJsOptions)
  }
}
