import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class Equalizer {
  constructor () {
    this.classes = {
      container: '.o-js-equalize-container',
      item: '.o-js-equalize-item'
    }
  }

  init () {
    window.addEventListener('load', e => this.getItems())
    EventUtil.addListeners(window, 'resize', e => this.getItems())
  }

  getItems () {
    GlobalUtil.forEach(document.querySelectorAll(this.classes.container), (index, container) => {
      const items = container.querySelectorAll(this.classes.item)
      const itemsIds = [...new Set(Array.from(items).map(items => items.dataset.itemId))]

      itemsIds.forEach(id => {
        const itemsFromId = Array.from(items).filter(item => item.dataset.itemId === id)

        this.setMaxHeight(itemsFromId)
      })
    })
  }

  setMaxHeight (items) {
    GlobalUtil.forEach(items, (index, items) => {
      items.style.height = 'auto'
    })

    if (items.length) {
      const maxHeight = Math.max(...Array.from(items).map(item => item.offsetHeight), 0)

      GlobalUtil.forEach(items, (index, item) => {
        item.style.height = maxHeight + 'px'
      })
    }
  }
}
