import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import FlatpickrLanguages from 'flatpickr/dist/l10n'

import GlobalUtil from '../Util/GlobalUtil'

export default class Datepicker {
  constructor () {
    this.inputs = document.querySelectorAll('.application_date_picker input')
  }

  init () {
    GlobalUtil.forEach(this.inputs, (index, input) => this.set(input))
  }

  set (input) {
    let flatpickrOptions = {
      altFormat: 'd/m/Y',
      altInput: true,
      locale: FlatpickrLanguages[GlobalUtil.getAppLocale()]
    }

    flatpickr(input, flatpickrOptions)
  }
}
