import './stylesheets/style'

import focusWithin from 'focus-within' // always before Rails
import Rails from './scripts/Rails/ujs'

// ---------- IMPORT COMPONENTS ----------
import Account from './scripts/Account'
import Action from './scripts/Action'
import Commerce from './scripts/Commerce'
import Confirm from './scripts/Tool/Confirm'
import Contact from './scripts/Contact'
import Directory from './scripts/Directory'
import FontLoader from './scripts/Tool/FontLoader'
import Form from './scripts/Form'
import Invitation from './scripts/Invitation'
import Layout from './scripts/Layout'
import Lightbox from './scripts/Tool/Lightbox'
import Membership from './scripts/Membership'
import Polyfill from './scripts/Tool/Polyfill'
import Slider from './scripts/Slider'
import Tool from './scripts/Tool'

// ---------- IMAGES ----------
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('./images', true, /\.(gif|jpg|png|svg|webp)$/i)
// const imagePath = (name) => images(name, true)

// ---------- POLYFILL ----------
const polyfill = new Polyfill()
polyfill.init()

// ---------- FONTS ----------
const fontLoader = new FontLoader()
fontLoader.init()

// ---------- APP ----------
class App {
  constructor () {
    // COMPONENTS
    this.account = new Account()
    this.action = new Action()
    this.commerce = new Commerce()
    this.confirm = new Confirm()
    this.contact = new Contact()
    this.directory = new Directory()
    this.form = new Form()
    this.invitation = new Invitation()
    this.layout = new Layout()
    this.lightbox = new Lightbox()
    this.membership = new Membership()
    this.slider = new Slider()
    this.tool = new Tool()
  }

  init () {
    document.body.classList.remove('no-js') // Remove class if JS is enabled
    focusWithin(document) // Focus within polyfill
    this.confirm.init() // Confirm init before Rails Start
    Rails.start()
    this.initComponents()
  }

  initComponents () {
    this.account.init()
    this.action.init()
    this.commerce.init()
    this.contact.init()
    this.directory.init()
    this.form.init()
    this.invitation.init()
    this.layout.init()
    this.lightbox.init()
    this.membership.init()
    this.slider.init()
    this.tool.init()
  }
}

document.addEventListener('DOMContentLoaded', e => {
  const app = new App()
  app.init()
})
