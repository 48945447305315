import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import MarkerImage from 'leaflet/dist/images/marker-icon.png'
import MarkerShadowImage from 'leaflet/dist/images/marker-shadow.png'
import Rails from './Rails/ujs'

import EventUtil from './Util/EventUtil'
import GlobalUtil from './Util/GlobalUtil'

export default class Directory {
  constructor () {
    this.classes = {
      scopeCheckbox: '.o-js-directory-scope-checkbox'
    }
    this.map = new DirectoryMap()
  }

  init () {
    window.addEventListener('load', e => this.map.init())

    EventUtil.delegateListeners('click', this.classes.scopeCheckbox, (e, checkbox) => {
      if (!checkbox.checked) {
        e.preventDefault()
        return false
      }

      const siblingCheckboxes = Array.from(document.querySelectorAll(this.classes.scopeCheckbox)).filter(el => el !== checkbox)

      siblingCheckboxes.forEach(siblingCheckbox => {
        siblingCheckbox.checked = false
      })

      this.map.scope = checkbox.dataset.scope
      this.map.resetMarkers()
      this.map.displayMarkers()
    })
  }
}

// --------------------------------------------------- //
//                                                     //
// --------------------------------------------------- //

class DirectoryMap {
  constructor () {
    this.classes = {
      container: '.o-js-directory-map'
    }
    this.config = {
      initialCoords: [38, 2.3488],
      initialZoom: 2,
      minZoom: 2,
      maxZoom: 4
    }
    this.container = document.querySelector(this.classes.container)
    this.datas = []
    this.leaflet = {
      markers: []
    }
    this.scope = ''
  }

  init () {
    if (this.container) {
      this.scope = this.container.dataset.scope
      this.initLeaflet()
    }
  }

  initLeaflet () {
    this.leaflet.map = L.map(this.container, {
      maxBounds: [
        [-90, -180],
        [90, 180]
      ]
    }).setView(this.config.initialCoords, this.config.initialZoom)

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      minZoom: this.config.minZoom,
      maxZoom: this.config.maxZoom,
      noWrap: true,
      bounds: [
        [-90, -180],
        [90, 180]
      ]
    }).addTo(this.leaflet.map)

    this.getData()
  }

  getData () {
    const dataUrl = this.container.dataset.url

    if (dataUrl) {
      Rails.ajax({
        url: dataUrl,
        type: 'GET',
        dataType: 'json',
        success: data => {
          this.datas = data
          this.displayMarkers()
        }
      })
    }
  }

  displayMarkers () {
    this.datas.forEach(data => {
      let scopeCount = 0
      let tooltipText = ''
      let url = '#'

      if (this.scope === 'experts') {
        scopeCount = data.expert_count
        tooltipText = data.expert_count_label
        url = data.expert_search_url
      } else if (this.scope === 'laboratories') {
        scopeCount = data.laboratory_count
        tooltipText = data.laboratory_count_label
        url = data.laboratory_search_url
      }

      if (scopeCount > 0 && !GlobalUtil.isBlank(data.latitude) && !GlobalUtil.isBlank(data.longitude)) {
        const coords = [data.latitude, data.longitude]

        let markerIcon = L.icon({
          iconUrl: MarkerImage,
          shadowUrl: MarkerShadowImage,
          iconSize: [17, 27],
          iconAnchor: [8.5, 27],
          shadowSize: [27, 27]
        })

        let marker = L.marker(coords, {
          icon: markerIcon,
          riseOnHover: true
        })

        this.leaflet.markers.push(marker)

        marker
          .addTo(this.leaflet.map)
          .bindTooltip(`<b>${data.name} :</b> ${tooltipText}`, {
            direction: 'bottom',
            offset: L.point(0, 0)
          })

        if (url === '#') {
          marker.bindPopup(`<b>${data.not_allowed_message}</b>`, {
            direction: 'top',
            offset: L.point(0, -20)
          })
        }

        EventUtil.addListeners(marker, 'click', e => {
          if (url === '#') {
            marker.openPopup()
          } else {
            window.location = url
          }
        })
      }
    })
  }

  resetMarkers () {
    this.leaflet.markers.forEach(marker => this.leaflet.map.removeLayer(marker))
  }
}
