import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import MarkerImage from 'leaflet/dist/images/marker-icon.png'
import MarkerShadowImage from 'leaflet/dist/images/marker-shadow.png'

export default class Contact {
  constructor () {
    this.map = new ContactMap()
  }

  init () {
    window.addEventListener('load', e => this.map.init())
  }
}

// --------------------------------------------------- //
//                                                     //
// --------------------------------------------------- //

class ContactMap {
  constructor () {
    this.classes = {
      container: '.o-js-contact-map'
    }
    this.config = {
      initialCoords: [48.8864912, 2.3050864],
      initialZoom: 11,
      minZoom: 10
    }
    this.container = document.querySelector(this.classes.container)
    this.leaflet = {}
  }

  init () {
    if (this.container) this.initLeaflet()
  }

  initLeaflet () {
    this.leaflet.map = L.map(this.container).setView(this.config.initialCoords, this.config.initialZoom)

    L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      minZoom: this.config.minZoom
    }).addTo(this.leaflet.map)

    let markerIcon = L.icon({
      iconUrl: MarkerImage,
      shadowUrl: MarkerShadowImage,
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
      shadowSize: [41, 41]
    })

    L.marker(this.config.initialCoords, {
      icon: markerIcon
    }).addTo(this.leaflet.map)
  }
}
