import MicroModal from 'micromodal'
import Rails from '../Rails/ujs'

import EventUtil from '../Util/EventUtil'
import FormUtil from '../Util/FormUtil'
import GlobalUtil from '../Util/GlobalUtil'

import Action from '../Action'
import Form from '../Form'
import Tooltip from './Tooltip'

export default class Modal {
  constructor() {
    this.triggerClass = '.o-js-modal-open'
  }

  init () {
    MicroModal.init({
      disableScroll: true,
      disableFocus: true
    })

    EventUtil.delegateListeners('click keyup', this.triggerClass, (e, trigger) => {
      if (trigger instanceof HTMLFormElement) return
      if (e.type === 'keyup' && ![13, 32].includes(e.keyCode)) return

      e.preventDefault()
      this.show({ trigger })
    })

    EventUtil.delegateListeners('submit', this.triggerClass, (e, trigger) => {
      e.preventDefault()

      const data = FormUtil.serialize(trigger)

      this.show({
        trigger,
        data,
        onShow: modal => {
          Rails.enableElement(trigger)
        }
      })
    })

    // Catch remotes in modal
    EventUtil.delegateListeners('ajax:complete', '.micromodal-slide.is-open [data-remote="true"]', e => this.initComponents())
  }

  show ({ trigger = undefined, url = undefined, method = undefined, data = {}, id = undefined, onShow = undefined, onClose = undefined } = {}) {
    if (!GlobalUtil.isBlank(trigger)) {
      if (GlobalUtil.isBlank(url)) {
        url = trigger.action
      }

      if (GlobalUtil.isBlank(url)) {
        url = trigger.href
      }

      if (GlobalUtil.isBlank(url)) {
        url = trigger.dataset.url
      }

      if (GlobalUtil.isBlank(method)) {
        method = trigger.method
      }

      if (GlobalUtil.isBlank(method)) {
        method = trigger.dataset.method
      }

      if (GlobalUtil.isBlank(id)) {
        id = trigger.dataset.id
      }
    }

    if (GlobalUtil.isBlank(method)) {
      method = 'GET'
    }

    if (GlobalUtil.isBlank(id)) {
      id = 'o-page-modal'
    }

    Rails.ajax({
      url: url,
      type: method,
      data: data,
      dataType: 'script',
      success: data => {
        document.body.classList.add('has-scroll-disabled')

        MicroModal.show(id, {
          onShow: modal => {
            this.initComponents()

            if (typeof tarteaucitron !== 'undefined') {
              tarteaucitron.triggerJobsAfterAjaxCall()
            }

            if (onShow) onShow(modal)
          },
          onClose: modal => {
            document.body.classList.remove('has-scroll-disabled')
            document.getElementById('o-page-modal-container').dataset.id = ''
            if (onClose) onClose(modal)
          }
        })
      }
    })
  }

  initComponents () {
    const action = new Action()
    const form = new Form()
    const tooltip = new Tooltip()

    action.init()
    form.init()
    tooltip.init()
  }
}
