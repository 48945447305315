import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

import GlobalUtil from '../Util/GlobalUtil'

export default class Flash {
  constructor () {
    this.triggers = document.querySelectorAll('[data-flash]')
  }

  init () {
    GlobalUtil.forEach(this.triggers, (index, trigger) => this.trigger(trigger.dataset.flash, trigger.dataset.message))
  }

  trigger (type, message) {
    const notyf = new Notyf({
      position: { 'x': 'right', 'y': 'top' },
      duration: 6000,
      types: [
        {
          type: 'error',
          backgroundColor: '#DB0000',
          icon: {
            className: 'fas fa-exclamation-triangle',
            tagName: 'i'
          }
        },
        {
          type: 'info',
          backgroundColor: '#0B6CB1',
          icon: {
            className: 'far fa-info-circle',
            tagName: 'i'
          }
        },
        {
          type: 'success',
          backgroundColor: '#4AB83E',
          icon: {
            className: 'far fa-check-square',
            tagName: 'i'
          }
        },
        {
          type: 'warning',
          backgroundColor: '#F9B033',
          icon: {
            className: 'far fa-exclamation-circle',
            tagName: 'i'
          }
        }
      ]
    })

    notyf.open({ type: type, message: message })
  }
}
