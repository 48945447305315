import Equalizer from './Equalizer'
import Flash from './Flash'
import LazyLoading from './LazyLoading'
import Modal from './Modal'
import Obfuscation from './Obfuscation'
import Tooltip from './Tooltip'

export default class Tool {
  constructor () {
    this.equalizer = new Equalizer()
    this.flash = new Flash()
    this.lazyLoading = new LazyLoading()
    this.modal = new Modal()
    this.obfuscation = new Obfuscation()
    this.tooltip = new Tooltip()
  }

  init () {
    this.equalizer.init()
    this.flash.init()
    this.lazyLoading.init()
    this.modal.init()
    this.obfuscation.init()
    this.tooltip.init()
  }
}
