import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class Toggle {
  constructor () {
    this.classes = {
      container: '.o-js-toggle-container',
      group: '.o-js-toggle-group',
      opened: '.is-opened',
      trigger: '.o-js-toggle-trigger'
    }
    this.triggerToggles = document.querySelectorAll(this.classes.trigger)
  }

  init () {
    GlobalUtil.forEach(this.triggerToggles, (index, trigger) => {
      const group = trigger.closest(this.classes.group)
      let target = trigger.closest(this.classes.container) || trigger // Change target if trigger is in container

      // Change target if is set in trigger
      if (!GlobalUtil.isBlank(trigger.dataset.target)) {
        target = document.querySelector(trigger.dataset.target)
      }

      EventUtil.addListeners(trigger, 'click keyup', e => {
        if (e.type !== 'keyup' || e.keyCode === 13 || e.keyCode === 32) {
          if (trigger.dataset.prevent === 'true') e.preventDefault()

          const triggerExpanded = trigger.getAttribute('aria-expanded')

          // Keep open option or not
          if (trigger.dataset.keepOpen === 'true') {
            this.show(target, e)
            if (triggerExpanded) trigger.setAttribute('aria-expanded', true) // Aria label
          } else {
            this.toggle(target, e)
            if (triggerExpanded) trigger.setAttribute('aria-expanded', triggerExpanded === 'false') // Aria label
          }

          // If group option is present
          if (group) this.hideSiblings(group, trigger)
        }
      })

      // Hide target if click is outside it
      if (!GlobalUtil.isBlank(target.dataset.hideOutside)) {
        EventUtil.addOutsideListeners(target, 'click', (e, untargetedElements) => {
          if (untargetedElements.length) this.hide(target)
        })
      }
    })
  }

  focus (el, e = undefined) {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      if (el.classList.contains(this.classes.container.substr(1))) {
        el = el.querySelector(this.classes.trigger)
      }

      const type = el.dataset.focus

      if (type === 'next') {
        let next = $(el).next()

        if (!next.data('focus-target')) next = next.find('[data-focus-target]')
        next.focus()
      }
    }
  }

  toggle (el, e = undefined) {
    el.classList.toggle(this.classes.opened.substr(1))
    if (el.classList.contains(this.classes.opened.substr(1))) this.focus(el, e)
  }

  show (el, e = undefined) {
    el.classList.add(this.classes.opened.substr(1))
    if (el.dataset.focus) this.focus(el, e)
  }

  hide (el) {
    el.classList.remove(this.classes.opened.substr(1))
  }

  hideSiblings (group, trigger) {
    const groupTriggers = group.querySelectorAll(this.classes.trigger)
    const siblingTriggers = Array.from(groupTriggers).filter(el => el !== trigger)

    siblingTriggers.forEach(sibling => {
      const target = sibling.closest(this.classes.container) || trigger

      this.hide(target)
    })
  }
}
