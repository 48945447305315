import EventUtil from '../Util/EventUtil'

export default class Print {
  constructor () {
    this.classes = {
      trigger: '.o-js-print'
    }
  }

  init () {
    EventUtil.delegateListeners('click', this.classes.trigger, e => {
      e.preventDefault()
      window.print()
    })
  }
}
