import EventUtil from '../Util/EventUtil'
import GlobalUtil from '../Util/GlobalUtil'

export default class File {
  constructor () {
    this.inputs = document.querySelectorAll('input.file')
    this.inputDeleteBtns = document.querySelectorAll('.o-js-input-file-delete')
  }

  init () {
    GlobalUtil.forEach(this.inputs, (index, input) => this.setInput(input))
    GlobalUtil.forEach(this.inputs, (index, input) => this.setInput(input))

    EventUtil.delegateListeners('click', '.o-js-input-file-delete', (e, button) => {
      const target = document.querySelector(`#${button.dataset.id}`)

      e.preventDefault()
      this.resetInput(target)
    })
  }

  resetInput (input) {
    input.value = ''
    input.dataset.filename = ''
    EventUtil.trigger(input, 'change')
  }

  setInput (input) {
    const parent = input.parentNode
    let inputClone = Array.from(parent.children).find(c => c.classList.contains('file-input'))

    if (!inputClone) {
      inputClone = document.createElement('div')
      inputClone.classList.add('file-input')
      parent.appendChild(inputClone)
    }

    // Set value
    inputClone.innerHTML = this.getInputName(input)

    // Set value on input change
    EventUtil.addListeners(input, 'change', e => {
      inputClone.innerHTML = this.getInputName(input)

      // Set file on target
      if (input.classList.contains('o-js-input-file-source')) {
        this.setInputFileToTarget(input)
      }
    })
  }

  getInputName (input) {
    let value = ''

    if (input.files.length > 0) {
      value = input.files[0].name
    } else if (!GlobalUtil.isBlank(input.dataset.filename)) {
      value = input.dataset.filename
    } else {
      value = `<span class="o-text-as-placeholder">${input.placeholder}</span>`
    }

    return `<p>${value}</p>`
  }

  setInputFileToTarget (input) {
    const target = document.querySelector(`.o-js-input-file-target[data-id="${input.id}"]`)
    const targetType = target.dataset.type

    if (input.files.length > 0) {
      const reader = new FileReader()

      reader.onload = e => {
        if (targetType === 'background') target.style.backgroundImage = `url(${e.target.result})`
      }

      reader.readAsDataURL(input.files[0])
    } else {
      if (targetType === 'background') target.style.backgroundImage = ''
    }
  }
}
