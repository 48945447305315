class Loader {
  show (selector = document.querySelector('#o-page-loader'), fullpage = false) {
    selector.classList.add('is-active')
    if (fullpage) selector.classList.add('is-fullpage')
  }

  hide (selector = document.querySelector('#o-page-loader')) {
    selector.classList.remove('is-active', 'is-fullpage')
  }
}

const instance = new Loader()
Object.freeze(instance)

export default instance
