import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'
import 'intl-tel-input/build/css/intlTelInput'

import GlobalUtil from '../Util/GlobalUtil'

export default class Phone {
  constructor () {
    this.inputs = document.querySelectorAll('.application_phone input')
  }

  init () {
    GlobalUtil.forEach(this.inputs, (index, input) => this.set(input))
  }

  set (input) {
    let intlTelInputOptions = {
      autoPlaceholder: 'aggressive',
      preferredCountries: ['fr', 'us', 'cn', 'in', 'gb'],
      separateDialCode: true,
      hiddenInput: 'phone'
    }

    if (input.disabled) {
      intlTelInputOptions.allowDropdown = false
    }

    if (!GlobalUtil.isBlank(input.dataset.initialCountry)) {
      intlTelInputOptions.initialCountry = input.dataset.initialCountry
    }

    intlTelInput(input, intlTelInputOptions)

    input.autocomplete = 'nope'
  }
}
